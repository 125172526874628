import React from 'react'





export default function Footer() {
    return (

        <div style={{padding:"0rem 5rem",marginTop:'-1rem'}} id="navba1" href="#about-us-section"
        class="scroll-button smoothscroll paddingLobby 

        
        dn-M" >
        
        <div class="row  fotterPadding" >
        


   

  
        
          </div>
        
        
        
        
        
        
        
        
        </div> 
    )
}
