import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import Cam from './photobhoot'
function App() {
  return (
    <>
    <Router>
    

    
      <Switch>
       
        
        <Route exact path="/">
        <Cam />
        </Route>
      
        
      </Switch>
    
  </Router>
  
    </>
  );
}

export default App;


